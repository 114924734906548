@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handjet:wght@100..900&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --purple: 258 52% 56%;
    --lightpurple: 258 52% 85%;
    --verylight: 258 52% 95%;
    --lavender: 240 25% 84%;
    --grey: 0 0% 96%;
    --white: 0 0% 100%;
    --black: 0 0% 0%;
  }

  * {
    @apply m-0 p-0 box-border;
  }

  body {
    @apply m-0 font-pt-serif-regular font-fraunces-slab font-work-sans-slab antialiased overflow-x-hidden;
  }

  @layer components {
    .shadow-text {
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
}

@layer utilities {
  .font-pt-serif-regular {
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
  }

  .font-fraunces-slab {
    font-family: "Fraunces", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
      "SOFT" 0,
      "WONK" 0;
  }

  .font-work-sans-slab {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }

  .font-handjet-slab {
    font-family: "Handjet", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
      "ELGR" 1,
      "ELSH" 2;
  }
}